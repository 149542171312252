import { width } from '@mui/system';
import { m } from 'framer-motion';

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};

export const paths = {
  home: '/',
  devices: '/devices',
  orders: '/orders',
  resellers: '/resellers',
  credits: '/credits',
  userSettings: '/user-settings',
  appSettings: '/app-settings',
  languageSettings: '/language-settings',
};

export const navConfig = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Manage Playlists',
    path: paths.managePlaylist,
  },
  {
    title: 'Activate',
    path: paths.activate,
  },
  {
    title: 'Contact Us',
    path: paths.contactUs,
  },
];

export const footerLinks = [
  {
    headline: 'Quick Links',
    children: [
      { name: 'About us', href: paths.aboutUs },
      { name: 'Contact us', href: paths.contactUs },
      { name: 'FAQs', href: paths.faq },
      { name: 'Manage Playlists', href: paths.managePlaylist },
      { name: 'Activate', href: paths.activate },
      { name: 'Become Reseller', href: paths.becomeReseller },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: paths.termsAndConditions },
      { name: 'Privacy Policy', href: paths.privacyPolicy },
    ],
  },
];

export const socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    hoverColor: '#1877F214',
    path: 'https://www.facebook.com/caitlyn.kerluke',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    hoverColor: '#E02D6914',
    path: 'https://www.instagram.com/caitlyn.kerluke',
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    hoverColor: '#007EBB14',
    path: 'https://www.linkedin.com/caitlyn.kerluke',
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
    hoverColor: '#00AAEC14',
    path: 'https://www.twitter.com/caitlyn.kerluke',
  },
];

export const PAYMENT_PRICING = [
  {
    tier: 1,
    price: 3.99,
    title: '1 Year',
  },
  {
    tier: 2,
    price: 4.99,
    title: 'Lifetime',
  },
];

// ADMIN

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PAGE_OFFSET = 0;

export const DEFAULT_PAGE_ORDER = 'desc'; // asc, desc

export const DEFAULT_DEVICE_FILTERS = {
  appTypes: [],
  paidStatus: [],
  macAddress: '',
  startDate: '',
  endDate: '',
};

export const DEFAULT_ORDER_FILTERS = {
  appTypes: [],
  status: [],
  paymentType: [],
  macAddress: '',
  startDate: '',
  endDate: '',
};

export const DEFAULT_CALCULATION_FILTERS = {
  appTypes: [],
  paymentType: [],
  startDate: '',
  endDate: '',
};

export const DEFAULT_ADMIN_FILTERS = {
  types: [],
  email: '',
  startDate: '',
  endDate: '',
};

export const DEFAULT_CREDIT_FILTERS = {
  email: '',
};

export const DEFAULT_DASHBOARD_PERIODS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'lastMonth',
    label: '1 Month',
  },
  {
    value: 'last3Months',
    label: '3 Months',
  },
  {
    value: 'lastYear',
    label: '1 Year',
  },
];

export const DEVICE_TABLE_HEAD = [
  { id: 'mac_address', label: 'Mac Address', width: 180 },
  { id: 'version', label: 'Version' },
  { id: 'expire_date', label: 'Expiration', width: 220 },
  { id: 'is_trial', label: 'Paid', width: 180 },
  { id: 'app_type', label: 'App Type', width: 150 },
  { id: 'created_time', label: 'Created At', width: 150 },
  { id: 'activated_at', label: 'Activated At', width: 200 },
  { label: 'Actions', width: 100 },
];

export const RESELLER_TABLE_HEAD = [
  { id: '', label: 'Photo', width: 80 },
  { id: 'name', label: 'Name', width: 120 },
  { id: 'email', label: 'Email', width: 180 },
  { id: 'type', label: 'Role', width: 220 },
  { id: 'credit', label: 'credit', width: 150 },
  { id: 'createdAt', label: 'Created At', width: 100 },
  { id: '', label: 'Actions', width: 100 },
];

export const CREDIT_TABLE_HEAD = [
  { id: '_id', label: 'ID', width: 150 },
  { id: 'name', label: 'Name', width: 120 },
  { id: 'email', label: 'Email', width: 180 },
  { id: 'type', label: 'Type', width: 220 },
  { id: 'credit_amount', label: 'Amount', width: 150 },
  { id: 'paid_amount', label: 'Paid', width: 150 },
  { id: 'createdAt', label: 'Created At', width: 100 },
  { id: '', label: 'Actions', width: 100 },
];

export const ORDER_TABLE_HEAD = [
  { id: 'mac_address', label: 'Mac Address', width: 150 },
  { id: 'payment_type', label: 'Payment Type', width: 100 },
  { id: 'app_type', label: 'App Type', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'pay_time', label: 'Paid Time', width: 100 },
  { id: 'payer_name', label: 'Payer Name', width: 100 },
  { id: 'payer_email', label: 'Payer Email', width: 100 },
  { id: 'amount', label: 'Amount', width: 100 },
];

export const DEVICE_APP_TYPES = [
  'android',
  'roku',
  'iOS',
  'tvOS',
  'macOS',
  'samsung',
  'lg',
  'vidaa',
  'zeasn',
  'windows',
];

export const ADMIN_TYPES = ['reseller']; // ['super-admin', 'reseller']

export const DEVICE_PAID_STATUS = ['Paid', 'Unpaid'];

export const ORDER_STATUS = ['success', 'pending', 'refund'];

export const PAYMENT_TYPES = ['stripe', 'paypal'];
